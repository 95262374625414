import React from "react";
import ReactWOW from "react-wow";
import client3 from "../../assets/images/team/team4.jpg";
import shape from "../../assets/images/shape/shape1.svg";

const ContactForm = () => {
	return (
		<section className="partner-area pt-10 pb-70 bg-f1f8fb">
			<div className="container">
				<br></br>
				<br></br>
				<div className="section-title">
					<h2>Des projets qui ont du sens.</h2>
					<span className="sub-title">
						Goodriders te permet d'apporter tes compétences à des
						projets à impact positif.
					</span>
				</div>

				<div className="single-testimonials-item">
					<p>
						Apporte tes compétences et deviens acteur du changement.
						Avoir de l'impact n'a jamais été aussi simple ! Quelque
						soit ton statut: salarié, freelance, en recherche
						d'emploi (et bientôt étudiant).
					</p>

					<div className="client-info">
						<div className="d-flex justify-content-center align-items-center">
							<img src={client3} alt="testimonial" />

							<div className="title">
								<h3> Guilhem, fondateur de GoodRiders !</h3>
								<span>Donner du sens au travail</span>
							</div>
						</div>
					</div>
				</div>

				<div className="testimonials-view-btn text-center">
					<div className="default-btn">
						<h3>Deviens goodrider</h3>
						<span></span>
					</div>
				</div>
			</div>

			<div className="shape-img1">
				<img src={shape} alt="testimonial" />
			</div>

			<br></br>
			<br></br>

			<div className="container">
				<div className="about-content text-center">
					<ReactWOW delay=".1s" animation="fadeInLeft"></ReactWOW>
				</div>

				<div className="row">
					<div className="col-lg-4 col-md-12">
						<div className="contact-image">
							<img
								src="https://static.wixstatic.com/media/e1b574_fbd46361298144ff8592663dcbb51128~mv2.png/v1/fill/w_353,h_134,al_c,q_85,usm_0.66_1.00_0.01/logo%203.webp"
								alt="Logo"
								className="publication-logo"
							/>
						</div>
					</div>

					<div className="col-lg-6 col-md-12">
						<div className="contact-form">
							<form id="contactForm">
								<div className="row">
									<div className="col-lg-12 col-md-6">
										<div className="form-group">
											<input
												type="email"
												name="email"
												className="form-control"
												required
												placeholder="Votre email"
											/>
										</div>
									</div>

									<div className="col-lg-12 col-md-12">
										<button
											type="submit"
											className="default-btn"
										>
											<i className="flaticon-tick"></i>
											ENVOYER <span></span>
										</button>
										<p>
											Rejoins des projets qui te
											correspondent.
										</p>
									</div>
								</div>
							</form>
						</div>
					</div>
					<br></br>
					<br></br>
				</div>
			</div>
		</section>
	);
};

export default ContactForm;
